<ng-container *ngIf="isSmallFooter; then smallFooter; else fullFooter"></ng-container>

<ng-template #smallFooter>
  <footer [class.footer_mod]="mod" class="footer d-flex justify-content-center align-items-center">
    <p [innerHTML]="'FOOTER.COPYWRITER' | translate : { year }" class="footer-text-small text-center"></p>
  </footer>
</ng-template>

<ng-template #fullFooter>
  <ng-container *ngIf="isWebSite; then landing; else cabinet"></ng-container>
</ng-template>

<ng-template #cabinet>
  <footer class="footer footer_cabinet">
    <div class="footer-container d-flex flex-column justify-content-center align-items-center">
      <div class="footer-row">
        <a [href]="'/'">
          <img alt="logo" class="footer-logo" src="../../assets/images/logo-white.svg" />
        </a>

        <app-button [label]="'FOOTER.PAY' | translate" class="button" color="primary" routerLink="freepayment" size="w100"></app-button>
      </div>
      <div class="footer-row footer-row_middle">
        <div class="footer-inner footer-inner_links">
          <a (click)="thirdPartyClickHandler(route.route)" *ngFor="let route of routes" [href]="route.route"
            >{{ route.label | translate }}</a
          >
        </div>
        <div class="footer-inner footer-inner_contacts">
          <a *ngFor="let email of emails" [href]="email.label" [innerHTML]="email.contact | translate"></a>
          <a [href]="phones[0].contact" [innerHTML]="phones[0].label + ' ' + smallTextForPhone | translate"></a>
          <a [href]="phones[1].contact" [innerHTML]="phones[1].label | translate"></a>
        </div>
        <div class="footer-inner footer-inner_time">
          <span class="title">Графік роботи:</span>
          <span *ngFor="let item of schedule" class="schedule">{{ item.days }}: <strong>{{ item.hours }}</strong></span>
          <span class="text">Видача кредитів <strong>24\7</strong></span>
        </div>
        <div class="footer-inner footer-inner_complaint">
          <span [innerHTML]="'FOOTER.COMPLAINT.TITLE' | translate" class="title"></span>
          <span [innerHTML]="'FOOTER.COMPLAINT.TEXT' | translate" class="text"></span>
        </div>
      </div>
      <div class="footer-row footer-row_copywrite">
        <p [innerHTML]="'FOOTER.COPYWRITER' | translate : { year: year }" class="footer-text-small text-center"></p>
        <a *ngIf="social.length" [href]="social[1].href" class="social-item"
          ><img [alt]="social[1].alt" [src]="social[1].img" class="social-img"
        /></a>
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #landing>
  <footer class="footer footer_landing">
    <div class="footer-container d-flex flex-column justify-content-center align-items-center">
      <div class="footer-row">
        <div class="footer-item">
          <div class="footer-inner">
            <img (click)="toHomePage()" alt="avans.credit" class="footer-logo" src="assets/images/logo-white.svg" />
            <a [routerLink]="'questions'" class="footer-link"> Поширені питання </a>
            <a [routerLink]="'contacts'" class="footer-link"> Контакти </a>
            <a class="footer-link" download href="assets/files/Свідоцтво про реєстрацію фінансової установи.pdf"> Свідоцтво </a>
          </div>
          <div class="footer-inner">
            <a [href]="emails[0].label" [innerHTML]="emails[0].contact" class="footer-link"></a>
            <a
              [href]="phones[0].contact"
              [innerHTML]="
                phones[0].label + ' ' + smallTextForPhone | translate
              "
              class="footer-link"
            ></a>
            <a [href]="phones[1].contact" [innerHTML]="phones[1].label | translate" class="footer-link"></a>
            <button (click)="openChat()" class="footer-btn" style="cursor: pointer">Залишити скаргу</button>
          </div>
          <div class="footer-inner">
            <p>Графік роботи:</p>
            <span *ngFor="let item of schedule" class="footer-text footer-text_light">
              {{ item.days }}: <strong>{{ item.hours }}</strong>
            </span>
            <span class="footer-text footer-text_light">Видача кредитів <strong>24\7</strong></span>
            <a [routerLink]="'freepayment'" class="footer-btn-freepayment"> Оплатити кредит </a>
          </div>
        </div>
        <div class="footer-item">
          <div>
            <p class="footer-text footer-text_light">
              ТОВ "Аванс Кредит" 01015, м. Київ, вул. Лейпцизька, 15А Код ЄДРПОУ: 43619089 Банк: АТ "ОТП Банк" Номер рахунку:
              UA063005280000026504000000241
            </p>
          </div>
          <a class="footer-text__link" href="assets/files/Повідомлення для військовослужбовців.pdf"
            >Повідомлення для військовослужбовців та їх дружин (чоловіків)</a
          >
          <div class="total-info d-flex flex-column">
            <p class="footer-text">Способи прийняття Компанією повідомлень про належність до захищеної категорії осіб:</p>
            <p class="footer-text footer-text_light">1. На юридичну адресу Компанії: 01015, м. Київ, вул. Лейпцизька,буд. 15А;</p>
            <p class="footer-text footer-text_light">
              2. На електронну адресу Компанії:
              <a [href]="emails[0].label" [innerHTML]="emails[0].contact" class="footer-link d-inline"></a>
            </p>
            <p class="footer-text footer-text_light">
              3. За номером телефону:
              <a [href]="phones[0].contact" [innerHTML]="phones[0].label" class="footer-link d-inline"></a>
            </p>
            <p class="footer-text footer-text_light">
              Свідоцтво про реєстрацію фінансової установи cерії ФК № В0000448, видане рішенням Національного банку України № 21/71-пк. від
              15.01.2021. <br />
              Ліцензія на діяльність фінансової компанії з правом надання коштів та банківських металів у кредит, видана Національним банком
              України 26.03.2024, строк дії – безстроково
            </p>
          </div>
        </div>
      </div>
      <div class="footer-row">
        <p [innerHTML]="'FOOTER.COPYWRITER' | translate : { year }" class="footer-text text-center"></p>
        <div class="social">
          <a *ngFor="let item of social" [href]="item.href" class="social-item"
            ><img [alt]="item.alt" [src]="item.img" class="social-img"
          /></a>
        </div>
      </div>
    </div>
  </footer>
</ng-template>
