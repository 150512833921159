<div [ngClass]="{ mobile_active: active }" class="mobile">
  <div class="mobile-header d-flex justify-content-between align-items-center">
    <div class="mobile-logo">
      <a [href]="env.WEBSITE_URL">
        <img [src]="env.LOGO_WHITE" alt="" class="header-logo-icon" height="100%" width="100%" />
      </a>
    </div>
    <div (click)="hamburgerEvent.emit()" [ngClass]="{ hamburger_active: active }" class="hamburger">
      <span class="hamburger__line"></span>
    </div>
  </div>

  <div *ngIf="isMenu" class="mobile-menu">
    <menu class="mobile-menu__wrap">
      <li *ngFor="let item of items" [routerLinkActiveOptions]="{ exact: true }" class="mobile-menu__item" routerLinkActive="active">
        <a (click)="itemClickEvent.emit()" [routerLink]="item.route" class="mobile-menu__link"> {{ item.label | translate }} </a>
      </li>
    </menu>
  </div>

  <div [class.justify-content-end]="!isMenu" class="mobile-footer">
    <div class="phone-container">
      <div class="phone-content">
        <h3 class="title">Клієнтська підтримка</h3>
        <a [href]="[phone.contact]" class="phone">
          {{ phone.label }}
          <br />
          <span class="phone-text"> безкоштовно </span>
        </a>
      </div>
    </div>

    <div (click)="logoutEvent.emit()" *ngIf="isLogged" class="mobile-logout mt-4">
      <span>Вийти</span>
      <svg fill="none" height="13" viewBox="0 0 17 13" width="17">
        <path
          clip-rule="evenodd"
          d="M10.713 0.25853L16.7234 5.87585C17.0922 6.22056 17.0922 6.77944 16.7234 7.12415L10.713 12.7415C10.3441 13.0862 9.74615 13.0862 9.37732 12.7415C9.0085 12.3968 9.0085 11.8379 9.37732 11.4932L13.7755 7.38268H0V5.61732H13.7755L9.37732 1.50682C9.0085 1.16212 9.0085 0.603237 9.37732 0.25853C9.74615 -0.0861767 10.3441 -0.0861767 10.713 0.25853Z"
          fill="white"
          fill-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</div>
