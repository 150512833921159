<section class="page">
  <app-header [isWebSite]="isWebSite" [navItems]="navItems" [phone]="phone" [steps]="steps" [webSiteItems]="webSiteItems"></app-header>
  <div [ngClass]="isWebSite ? 'page-container_site' : 'page-container_cabinet'" class="page-container">
    <div class="page-phone">
      <app-phone-widget [phone]="phone"></app-phone-widget>
    </div>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer
    [emails]="emails"
    [isSmallFooter]="isSmallFooter"
    [isWebSite]="isWebSite"
    [phones]="phones"
    [routes]="footerRoutes"
    [schedule]="schedule"
  ></app-footer>
</section>
<app-recaptcha></app-recaptcha>
