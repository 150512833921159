import { Component, Inject, Self } from '@angular/core';
import { IContact } from '@libs/models/models';
import { NavigationEnd, Router } from '@angular/router';
import { UnsubscribeService } from '@libs/shared/services/unsubscribe.service';
import { takeUntil } from 'rxjs/operators';
import { Config, navItems, navItemsFooter, schedule, webSiteItems } from './configs/config';
import { NavItem } from '@libs/models/nav-item.interface';
import { Schedule } from '@libs/types/shedule.type';
import { IItem } from '@libs/shared/components/stepper-widget/stepper-widget.component';
import { WINDOW } from '@libs/shared/injections/window';
import { crutchHelper } from '@libs/helpers/login.helper';
import { conditionalOperator } from '@libs/shared/operators/conditional.operator';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [UnsubscribeService],
})
export class AppComponent {
  public readonly phone: IContact = Config.phones[0];
  public readonly navItems: NavItem[] = navItems;
  public readonly webSiteItems: NavItem[] = webSiteItems;
  public isSmallFooter: boolean = false;
  public isWebSite: boolean = false;
  public footerRoutes = navItemsFooter;
  public phones: IContact[] = [...Config.phones];
  public emails: IContact[] = Config.emails;
  public schedule: Schedule[] = schedule;
  public steps: IItem[] = [{ url: '/auth/login' }, { url: '/form/profile' }, { url: '/form/busyness' }];

  constructor(private router: Router, @Inject(WINDOW) private readonly window: Window, @Self() private destroyStream$: UnsubscribeService) {
    crutchHelper(this.window, this.router);
    this.window['dataLayer'] = this.window['dataLayer'] ?? [];
    this.router.events
      .pipe(
        conditionalOperator({
          condition: (event) => event instanceof NavigationEnd,
          trueCase: (event: NavigationEnd) => {
            this.isSmallFooter = ['main', 'form', 'system', 'verification', 'bankid'].some((v: string) =>
              event.urlAfterRedirects.includes(v),
            );
            this.isWebSite = ['home', 'questions', 'contacts', 'documents', 'blog', 'promotions'].some((v: string) =>
              event.urlAfterRedirects.includes(v),
            );
          },
        }),
        takeUntil(this.destroyStream$),
      )
      .subscribe();
  }
}
