import * as Models from '../../../../../libs/models/models';
import { Schedule } from '@libs/types/shedule.type';
import { NavItem } from '@libs/models/nav-item.interface';

export class Config {
  static phones: Array<Models.IContact> = [
    {
      label: '0800 20 10 40',
      contact: 'tel:0800201040',
    },
    {
      label: '+38 073 0 20 10 40',
      contact: 'tel:+380730201040',
    },
  ];

  static emails: Array<Models.IContact> = [
    {
      label: 'mailto:info@finbar.com.ua',
      contact: 'info@finbar.com.ua',
    },
  ];
}

export const schedule: Schedule[] = [
  {
    days: 'Пн-Пт',
    hours: '9:00 - 18:00',
  },
  {
    days: 'Сб-Нд',
    hours: 'вихідні',
  },
];

export const navItems: NavItem[] = [
  {
    label: 'NAVIGATION.LOAN',
    route: 'main/loan',
  },
  {
    label: 'NAVIGATION.HISTORY',
    route: 'main/history',
  },
  {
    label: 'NAVIGATION.PROFILE',
    route: 'main/profile',
  },
  {
    label: 'NAVIGATION.UPLOAD',
    route: 'main/upload',
  },
];

export const webSiteItems: NavItem[] = [
  {
    label: 'Публічна інформація',
    route: '/documents',
  },
  {
    label: 'Поширені питання',
    route: '/questions',
  },
  {
    label: 'Контакти',
    route: '/contacts',
  },
];

export const navItemsFooter: NavItem[] = [
  {
    label: 'NAVIGATION.QUESTIONS',
    route: '/questions',
  },
  {
    label: 'NAVIGATION.CONTACTS',
    route: '/contacts',
  },
  {
    label: 'NAVIGATION.DOCS',
    route: '/documents',
  },
];
