export const environment = {
  production: true,
  name: 'FINBAR',
  group: 'AVANS',
  hostUrl: `https://client.gateway.finbar.com.ua/v1/api/rest`,
  isTestPaymentEnabled: false,
  isInnValidationEnabled: true,
  recaptchaKEY_CODE: '6Lde7wMqAAAAAMZIpB-gZw2Ft2xKK6DjDqmb_bH6',
  cookieDomain: '.finbar.com.ua',
  WEBSITE_URL: 'https://finbar.com.ua/',
  CLICK_URL: 'https://click.finbar.com.ua/',
  LOGO_CABINET: '../assets/images/logo.svg',
  LOGO_SITE: '../assets/images/logo.svg',
  LOGO_WHITE: '../assets/images/logo-white.svg',
  CONTACT: 'tel:0800201040',
  GA_TAG: 'H12QWVN5VE',
  partnerLink: 'https://finbar.online/?campaign=4830284&utm_content=&reg_user=',
  secondPartnerLink: 'https://avans.g2afse.com/click?pid=116&offer_id=46&sub2=',
  helpCranchKey: '6ac39d3a-0b9e-4d1c-93d6-2c2f0a02df53',
  helpCranchOrganization: 'avanscredit',
};
